import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  templateUrl: './requestResetPasswordConfirmation.component.html',
  styleUrls: ['./requestResetPasswordConfirmation.component.less']
})
export class RequestResetPasswordConfirmationComponent implements OnInit, OnDestroy {
  title = 'request-reset-password-confirmation-page';
  token: string;

  constructor(
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }
}
