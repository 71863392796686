import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {AccountModule} from '../account/account.module';
import {AccountChangePasswordComponent} from './accountChangePassword.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AlertModule} from '../shared/alert/alert.module';

@NgModule({
  declarations: [
    AccountChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AccountModule,
    ReactiveFormsModule,
    AlertModule,
  ],
  providers: [
  ],
  exports: [
  ],
  bootstrap: [
    AccountChangePasswordComponent,
  ]
})
export class AccountChangePasswordModule { }
