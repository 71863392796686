import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ShopCategoryComponent} from './shopCategory.component';
import {ShopProductModule} from '../shopProduct/shopProduct.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    ShopCategoryComponent,
  ],
  imports: [
    BrowserModule,
    ShopProductModule,
    RouterModule,
  ],
  providers: [],
  exports: [
  ],
  bootstrap: [
    ShopCategoryComponent,
  ]
})
export class ShopCategoryModule { }
