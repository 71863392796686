import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {AlertComponent} from './alert.component';

@NgModule({
  declarations: [
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [
  ],
  exports: [
    AlertComponent,
  ],
  bootstrap: [
    AlertComponent,
  ]
})
export class AlertModule { }
