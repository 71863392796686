import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Card} from '../../models/card';
import {CardService} from '../../services/card.service';

@Injectable()
export class UserCardsResolve  {
  constructor(private cardService: CardService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Card[]> {
    return this.cardService.findUserCards();
  }
}
