import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ShopHomeComponent} from './main/shopHome/shopHome.component';
import {ShopProductComponent} from './main/shopProduct/shopProduct.component';
import {ShopCategoryComponent} from './main/shopCategory/shopCategory.component';
import {ShopCartComponent} from './main/shopCart/shopCart.component';
import {ShopCheckoutComponent} from './main/shopCheckout/shopCheckout.component';
import {VoucherResolve} from './main/shopProduct/resolver/voucher.resolve';
import {CartResolve} from './main/shopCart/resolver/cart.resolve';
import {PaymentConfirmationComponent} from './main/paymentConfirmation/paymentConfirmation.component';
import {LoginPageComponent} from './main/account/loginPage.component';
import {RegisterPageComponent} from './main/account/registerPage.component';
import {LogoutPageComponent} from './main/account/logoutPage.component';
import {AccountPageComponent} from './main/account/accountPage.component';
import {CardListComponent} from './main/card/cardList.component';
import {UserCardsResolve} from './main/card/resolver/userCards.resolve';
import {OrdersListComponent} from './main/order/ordersList.component';
import {OrdersResolve} from './main/order/resolver/orders.resolve';
import {AccountDataComponent} from './main/accountData/accountData.component';
import {UserDataResolve} from './main/accountData/resolver/userData.resolve';
import {AccountChangePasswordComponent} from './main/accountChangePassword/accountChangePassword.component';
import {PageComponent} from './main/page/page.component';
import {RequestResetPasswordComponent} from './main/resetPassword/requestResetPassword.component';
import {ResetPasswordComponent} from './main/resetPassword/resetPassword.component';
import {AccountConfirmationComponent} from './main/accountConfirmation/accountConfirmation.component';
import {ResetPasswordConfirmationComponent} from './main/resetPassword/resetPasswordConfirmation.component';
import {RequestResetPasswordConfirmationComponent} from './main/resetPassword/requestResetPasswordConfirmation.component';
import {AccountDataFormComponent} from './main/accountData/accountDataForm.component';
import {ExistingAccountPasswordComponent} from './main/existingAccountPassword/existingAccountPassword.component';
import {AcceptRegulationsAndChangePasswordComponent} from './main/acceptRegulationsAndChangePassword/acceptRegulationsAndChangePassword.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: ShopHomeComponent,
  //   resolve: [],
  // },
  {
    path: 'product/:slug',
    component: ShopProductComponent,
    resolve: {
      voucher: VoucherResolve,
    },
  },
  {
    path: '',
    component: ShopCategoryComponent,
    resolve: [],
  },
  {
    path: 'cart',
    component: ShopCartComponent,
    resolve: {
      cart: CartResolve,
    },
  },
  {
    path: 'checkout',
    component: ShopCheckoutComponent,
    resolve: {
      cart: CartResolve,
    },
  },
  {
    path: 'payment-confirmation',
    component: PaymentConfirmationComponent,
    resolve: {
      cart: CartResolve,
    },
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'register',
    component: RegisterPageComponent,
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'account',
    component: AccountPageComponent,
  },
  {
    path: 'account/cards',
    component: CardListComponent,
    resolve: {
      cards: UserCardsResolve,
    }
  },
  // {
  //   path: 'account/orders',
  //   component: OrdersListComponent,
  //   resolve: {
  //     orders: OrdersResolve,
  //   }
  // },
  {
    path: 'account/data',
    component: AccountDataComponent,
    resolve: {
      userData: UserDataResolve,
    }
  },
  {
    path: 'account/edit',
    component: AccountDataFormComponent,
    resolve: {
      userData: UserDataResolve,
    }
  },
  {
    path: 'account/change-password',
    component: AccountChangePasswordComponent
  },
  {
    path: 'page/:slug',
    component: PageComponent,
  },
  {
    path: 'request-reset-password',
    component: RequestResetPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'account-confirmation/:token',
    component: AccountConfirmationComponent,
  },
  {
    path: 'reset-password-confirmation',
    component: ResetPasswordConfirmationComponent,
  },
  {
    path: 'request-reset-password-confirmation',
    component: RequestResetPasswordConfirmationComponent,
  },
  {
    path: 'existing-account-recovery',
    component: ExistingAccountPasswordComponent,
  },
  {
    path: 'accept-regulations-and-change-password',
    component: AcceptRegulationsAndChangePasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
