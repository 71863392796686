import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../../environments/environment';
import {Bank} from '../models/tpay/bank';
import {VoucherTemplate} from '../models/voucherTemplate';
import {VoucherTemplates} from '../models/voucherTemplates';
import {Gallery} from '../models/gallery';
import {VoucherTemplateGroup} from '../models/voucherTemplateGroup';
import {VoucherTemplateGroups} from '../models/voucherTemplateGroups';

@Injectable({ providedIn: 'root' })
export class PublicApiService {
  constructor(
    private router: Router,
    private http: HttpClient,
  ) {

  }

  getVouchers() {
    return this.http.get<VoucherTemplates>(`${environment.apiEndpoint}/v1/vouchers?sortCol=ecommerceSalePrice&sortDir=ASC`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }

  getVoucherGallery(id: string) {
    return this.http.get<Gallery>(`${environment.apiEndpoint}/v1/cms/gallery/` + id + `/type/VoucherTemplate/www`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }

  getImage(id: string, galleryId: string) {
    return this.http.get<string>(`${environment.apiEndpoint}/v1/cms/gallery/image/` + galleryId + '/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }

  getVoucherBySlug(slug: string) {
    return this.http.get<VoucherTemplate>(`${environment.apiEndpoint}/v1/vouchers/show/` + slug, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }

  getGroups() {
    return this.http.get<VoucherTemplateGroups>(`${environment.apiEndpoint}/v1/voucher-groups`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }
}
