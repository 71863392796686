import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountService} from '../services/account.service';
import {first} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
// import {FacebookLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-login-form',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit{
  control: UntypedFormControl = new UntypedFormControl('');
  loginForm: UntypedFormGroup;
  errorCode: string = '';
  errorMessage: string = '';
  isLoggedIn$: Observable<boolean>;
  invalidLoginData = false;
  // socialUser!: SocialUser;
  @Output() add = new EventEmitter();
  @Input() voucher;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    // private socialAuthService: SocialAuthService,
  ) {
    this.isLoggedIn$ = this.accountService.isLoggedIn;
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      login: ['', Validators.required]
    });
  }
  ngOnInit() {
    // this.socialAuthService.authState.subscribe((user) => {
    //   this.socialUser = user;
    // });
  }

  get loginControl() {
    return this.loginForm.controls.login;
  }

  get passwordControl() {
    return this.loginForm.controls.password;
  }

  submit() {
    this.errorMessage = '';

    if (this.loginForm.invalid) {
      return;
    }

    this.accountService.login(this.loginForm.value.login, this.loginForm.value.password).pipe(first())
      .subscribe(response => {
        this.isLoggedIn$ = this.accountService.isLoggedIn;
        // if (this.accountService.currentUser.confirmedAt) {
          if (localStorage.getItem('cartId')) {
            this.router.navigate(['/cart']);
          } else {
            this.router.navigate(['/account']);
          }
        // } else {
        //   this.router.navigate(['/accept-regulations-and-change-password']);
        // }
      }, error => {
        this.invalidLoginData = true;
        // this.errorCode = error.error.code;
        // this.errorMessage = error.error.message;
      });
  }

  register() {
    this.router.navigate(['/register']);
  }

  // loginWithFacebook(): void {
  //   this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }
  //
  // signOut(): void {
  //   this.socialAuthService.signOut();
  // }
}
