import {Component, OnDestroy, OnInit} from '@angular/core';
import 'rxjs/add/operator/takeWhile';

@Component({
  templateUrl: './loginPage.component.html',
  styleUrls: ['./loginPage.component.less']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  title = 'login-page';

  constructor(
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }
}
