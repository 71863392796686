import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ShopHomeModule} from './main/shopHome/shopHome.module';
import {FooterComponent} from './main/shared/layout/footer.component';
import {HeaderComponent} from './main/shared/layout/header.component';
import {ShopCategoryModule} from './main/shopCategory/shopCategory.module';
import {ShopProductModule} from './main/shopProduct/shopProduct.module';
import {ShopCartModule} from './main/shopCart/shopCart.module';
import {ShopCheckoutModule} from './main/shopCheckout/shopCheckout.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccountModule} from './main/account/account.module';
import {ErrorInterceptor} from './main/helpers/error.interceptor';
import {PaymentConfirmationModule} from './main/paymentConfirmation/paymentConfirmation.module';
import {MenuComponent} from './main/shared/menu/menu.component';
import {CardModule} from './main/card/card.module';
import {OrderModule} from './main/order/order.module';
import {AccountDataModule} from './main/accountData/accountData.module';
import {AccountChangePasswordModule} from './main/accountChangePassword/accountChangePassword.module';
import {AlertModule} from './main/shared/alert/alert.module';
import {AlertComponent} from './main/shared/alert/alert.component';
// import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PageModule} from './main/page/page.module';
import {ResetPasswordModule} from './main/resetPassword/resetPassword.module';
import {AccountConfirmationModule} from './main/accountConfirmation/accountConfirmation.module';
import {ExistingAccountPasswordModule} from './main/existingAccountPassword/existingAccountPassword.module';
import {AcceptRegulationsAndChangePasswordModule} from './main/acceptRegulationsAndChangePassword/acceptRegulationsAndChangePassword.module';
// import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // PdfViewerModule,
    ShopHomeModule,
    ShopCategoryModule,
    ShopProductModule,
    ShopCategoryModule,
    ShopCartModule,
    ShopCheckoutModule,
    AppRoutingModule,
    AccountModule,
    PaymentConfirmationModule,
    CardModule,
    OrderModule,
    AccountDataModule,
    AccountChangePasswordModule,
    AlertModule,
    PageModule,
    ResetPasswordModule,
    AccountConfirmationModule,
    ExistingAccountPasswordModule,
    AcceptRegulationsAndChangePasswordModule,
    // SocialLoginModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider('114017915307597'),
    //       },
    //     ],
    //   } as SocialAuthServiceConfig,
    // },
  ],
  bootstrap: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
  ]
})
export class AppModule { }
