import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'minus-from-cart',
  template: '<input type="button" class="minus" value="-" (click)="remove.next(voucher)">'
})

export class MinusFromCartComponent {
  control: UntypedFormControl = new UntypedFormControl('');
  @Output() remove = new EventEmitter();
  @Input() voucher;
}
