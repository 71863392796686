import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {AccountModule} from '../account/account.module';
import {AccountDataComponent} from './accountData.component';
import {UserDataResolve} from './resolver/userData.resolve';
import {AccountDataFormComponent} from './accountDataForm.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AccountDataComponent,
    AccountDataFormComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AccountModule,
    ReactiveFormsModule,
  ],
  providers: [
    UserDataResolve,
  ],
  exports: [
  ],
  bootstrap: [
    AccountDataComponent,
    AccountDataFormComponent,
  ]
})
export class AccountDataModule { }
