import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'add-into-cart',
  template: '<input type="button" class="plus" value="+" (click)="add.next(voucher)">'
})

export class AddIntoCartComponent {
  control: UntypedFormControl = new UntypedFormControl('');
  @Output() add = new EventEmitter();
  @Input() voucher;
}
