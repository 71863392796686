import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'add-to-cart',
  template: '<button class="btn btn-circled text-uppercase" style="background-color: #c43f32; color: #fff; font-size:13px; font-weight:700; padding: 10px 20px" (click)="add.next(voucher)" type="button">KUP KARNET</button>'
})

export class AddToCartComponent {
  control: UntypedFormControl = new UntypedFormControl('');
  @Output() add = new EventEmitter();
  @Input() voucher;
}
