import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './shopHome.component.html',
  styleUrls: ['./shopHome.component.less']
})
export class ShopHomeComponent implements OnInit {
  title = 'fit-shop';

  constructor() {
  }

  ngOnInit(): void {
  }
}
