
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../../environments/environment';
import {Banks} from '../models/tpay/banks';
import {Bank} from '../models/tpay/bank';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(
    private router: Router,
    private http: HttpClient,
  ) {

  }

  getBanks() {
    return this.http.get<Bank[]>(`${environment.apiEndpoint}/v1/payment/banks`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }
}
