import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {VoucherTemplate} from '../../models/voucherTemplate';
import {PublicApiService} from '../../services/publicApi.service';

@Injectable()
export class VoucherResolve  {
  constructor(private publicApiService: PublicApiService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<VoucherTemplate> {
    return this.publicApiService.getVoucherBySlug(route.params.slug);
  }
}
