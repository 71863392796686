
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Card} from '../models/card';
import {AccountService} from './account.service';
import {Orders} from '../models/orders';

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService,
  ) {

  }

  findUserOrders() {
    return this.http.get<Orders>(`${environment.internalApiEndpoint}/v1/orders/get/my`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.accountService.getToken(),
      }),
    });
  }
}
