import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {User} from '../../models/user';
import {AccountService} from '../../services/account.service';

@Injectable()
export class UserDataResolve  {
  constructor(private accountService: AccountService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this.accountService.getCurrentAccount();
  }
}
