import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ShopHomeComponent} from './shopHome.component';

@NgModule({
  declarations: [
    ShopHomeComponent,
  ],
  imports: [
    BrowserModule,
  ],
  providers: [],
  exports: [
  ],
  bootstrap: [
    ShopHomeComponent,
  ]
})
export class ShopHomeModule { }
