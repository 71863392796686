import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ShopCheckoutComponent} from './shopCheckout.component';
import {AccountModule} from '../account/account.module';

@NgModule({
  declarations: [
    ShopCheckoutComponent,
  ],
  imports: [
    BrowserModule,
    AccountModule,
  ],
  providers: [
  ],
  exports: [
  ],
  bootstrap: [
    ShopCheckoutComponent,
  ]
})
export class ShopCheckoutModule { }
