
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Card} from '../models/card';
import {AccountService} from './account.service';

@Injectable({ providedIn: 'root' })
export class ResetPasswordService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService,
  ) {

  }

  requestResetPassword(email: string) {
    return this.http.post(`${environment.loginApiEndpoint}/auth/request-reset-password`, {
      username: email
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }

  resetPassword(token: string, firstPassword: string, secondPassword: string) {
    return this.http.post(`${environment.loginApiEndpoint}/auth/reset-password/` + token, {
      password: {
        first: firstPassword,
        second: secondPassword
      }
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }
}
