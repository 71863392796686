import {CartItem} from './cartItem';

export class Cart {
  id: string;
  items: CartItem[];
  createdAt: string;
  updatedAt: string;
  transactionMd5: string;
  paymentToken: string;
  externalPaymentVerified: boolean;
  paymentStartedAt: string;
  paid: boolean;
  paymentError: boolean;
}
