import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../services/account.service';
import {first} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../shared/alert/alert.service';

@Component({
  templateUrl: './existingAccountPassword.component.html',
  styleUrls: ['./existingAccountPassword.component.less']
})
export class ExistingAccountPasswordComponent implements OnInit, OnDestroy {
  title = 'existing-account-password-page';
  tempPasswordForm: UntypedFormGroup;
  errorMessage: string = '';
  errorMessageCode: number;
  passwordSend = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
  ) {
    this.tempPasswordForm = this.formBuilder.group({
        email: ['', Validators.required]
      });
  }

  get emailControl() {
    return this.tempPasswordForm.controls.email;
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  submit() {
    this.errorMessage = '';

    if (this.tempPasswordForm.invalid) {
      return;
    }

    this.accountService.createFirstTimePassword(this.tempPasswordForm.value.email).pipe(first())
      .subscribe(response => {
        this.alertService.success('Tymczasowe hasło zostało wosłane na wskazany adres e-mail.', { keepAfterRouteChange: true });
        this.passwordSend = true;
      }, err => {
        this.errorMessage = err;
      });
  }
}
