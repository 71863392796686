import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AccountService} from '../services/account.service';
import {first} from 'rxjs/operators';
import {AlertService} from '../shared/alert/alert.service';

@Component({
  templateUrl: './accountChangePassword.component.html',
  styleUrls: ['./accountChangePassword.component.less']
})
export class AccountChangePasswordComponent implements OnInit, OnDestroy {
  title = 'account-change-password-page';
  changePasswordForm: UntypedFormGroup;
  errorMessage: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private alertService: AlertService,
  ) {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validators: this.checkPasswords });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('newPassword').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  get currentPasswordControl() {
    return this.changePasswordForm.controls.currentPassword;
  }

  get newPasswordControl() {
    return this.changePasswordForm.controls.newPassword;
  }

  get confirmPasswordControl() {
    return this.changePasswordForm.controls.confirmPassword;
  }

  submit() {
    this.errorMessage = '';

    if (this.changePasswordForm.invalid) {
      return;
    }

    this.accountService.changePassword(
      this.changePasswordForm.value.currentPassword, this.changePasswordForm.value.newPassword, this.changePasswordForm.value.confirmPassword).pipe(first())
      .subscribe(response => {
        this.alertService.success('Hasło zostało zmienione', { keepAfterRouteChange: true });
        this.router.navigate(['/account']);
      }, error => {
        this.alertService.error(error, { keepAfterRouteChange: true });
        // this.errorCode = error.error.code;
        // this.errorMessage = error.error.message;
      });
  }
}
