import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit, OnDestroy {
  title = 'static-page';
  isPdf = false;
  contentFile: string;
  pages = {
    rodo: {
      content: './../assets/pdf/rodo.pdf',
      isPdf: true,
      title: 'Regulamin polityki prywatności'
    },
    regulamin: {
      content: './../assets/pdf/regulamin.pdf',
      isPdf: true,
      title: 'Regulamin serwisu internetowego'
    }
  };
  slug: string;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.slug = this.route.snapshot.params.slug;

    if (this.pages[this.slug]) {
      this.isPdf = this.pages[this.slug].isPdf;
      this.contentFile = this.pages[this.slug].content;
      this.title = this.pages[this.slug].title;
    }
  }

  ngOnDestroy() {

  }
}
