import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {AccountModule} from '../account/account.module';
import {PageComponent} from './page.component';
// import {PdfViewerModule} from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    PageComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AccountModule,
    // PdfViewerModule,
  ],
  providers: [
  ],
  exports: [
  ],
  bootstrap: [
    PageComponent,
  ]
})
export class PageModule { }
