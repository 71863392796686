import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Orders} from '../models/orders';

@Component({
  templateUrl: './ordersList.component.html',
  styleUrls: ['./ordersList.component.less']
})
export class OrdersListComponent implements OnInit, OnDestroy {
  title = 'orders-list-page';
  orders: Orders;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.orders = this.route.snapshot.data.orders;
  }

  ngOnDestroy() {

  }
}
