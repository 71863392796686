import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DataLayerService} from './main/services/data-layer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'fit-shop';

  constructor(
    private router: Router,
    private dataLayerService: DataLayerService
  ) {

    this.router.events.subscribe(event => { // subscribe to router events
      if (event instanceof NavigationEnd) {
        this.dataLayerService.logPageView(event.url);
      }
    });

  }

  onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
