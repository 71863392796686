import { Component, OnInit } from '@angular/core';
import {PublicApiService} from '../services/publicApi.service';
import {VoucherTemplate} from '../models/voucherTemplate';
import {first} from 'rxjs/operators';
import {VoucherTemplates} from '../models/voucherTemplates';
import {ShopCartService} from '../shopCart/shopCart.service';
import {Gallery} from '../models/gallery';

declare var gtag;

@Component({
  templateUrl: './shopCategory.component.html',
  styleUrls: ['./shopCategory.component.less']
})
export class ShopCategoryComponent implements OnInit {
  title = 'fit-shop';
  vouchers: VoucherTemplate[];
  usedGroups = [];

  constructor(
    private publicApiService: PublicApiService,
    private shopCartService: ShopCartService
  ) {
  }

  ngOnInit(): void {
    this.publicApiService.getVouchers().pipe(first())
      .subscribe((res: VoucherTemplates) => {
          this.vouchers = res.items;
          for (let i = 0; i < this.vouchers.length; i++) {

            if (this.vouchers[i].group) {
              let exists = false;

              for (let z = 0; z < this.usedGroups.length; z++) {
                if (this.usedGroups[z].id === this.vouchers[i].group.id) {
                  exists = true;
                  break;
                }
              }

              if (!exists) {
                this.usedGroups.push(this.vouchers[i].group);
              }
            }

            this.publicApiService.getVoucherGallery(this.vouchers[i].id).pipe(first())
              .subscribe((gallery: Gallery) => {
                if (gallery) {
                  this.vouchers[i].gallery = gallery;
                  for (let j = 0; j < this.vouchers[i].gallery.items.length; j++) {
                    if (!this.vouchers[i].gallery.items[j].fillImage) {
                      continue;
                    }

                    this.publicApiService.getImage(this.vouchers[i].gallery.items[j].id, this.vouchers[i].gallery.id).pipe(first())
                      .subscribe((image: string) => {
                        if (image) {
                          this.vouchers[i].gallery.items[j].image = image;
                          this.vouchers[i].gallery.fillImage = image;
                          this.vouchers[i].gallery.fillImageAlt = this.vouchers[i].gallery.items[j].altName;
                        }
                      });
                  }
                }
              });
          }

          this.registerGtag(res);
        },
        error => {

        });
  }

  addVoucherToCart(voucher: VoucherTemplate, quantity: number) {
    this.shopCartService.addToCart(voucher, quantity);
  }

  registerGtag(vouchers: VoucherTemplates) {
    const items = [];

    for (let i = 0; i < vouchers.items.length; i++) {
      items.push({
        item_id: vouchers.items[i].slug,
        item_name: vouchers.items[i].name,
        affiliation: 'karnety.bodyspace.pl',
        coupon: '',
        discount: 0,
        index: 0,
        item_brand: 'Body Space',
        item_category: vouchers.items[i].group.name,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: 'vouchers_list',
        item_list_name: 'Vouchers list',
        item_variant: '',
        location_id: '',
        price: vouchers.items[i].ecommerceSalePrice,
        quantity: 1
      });
    }

    gtag('event', 'view_item_list', {
      item_list_id: 'vouchers_list',
      item_list_name: 'Vouchers list',
      items: items
    });
  }
}
