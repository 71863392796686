
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Card} from '../models/card';
import {AccountService} from './account.service';

@Injectable({ providedIn: 'root' })
export class CardService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService,
  ) {

  }

  findUserCards() {
    return this.http.get<Card[]>(`${environment.internalApiEndpoint}/v1/my-cards`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.accountService.getToken(),
      }),
    });
  }
}
