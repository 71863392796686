import { Component, OnInit } from '@angular/core';
import { get } from 'scriptjs';
import { environment } from '../../../environments/environment';
import {PaymentService} from '../services/payment.service';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {Bank} from '../models/tpay/bank';
import {Cart} from '../models/cart';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountService} from '../services/account.service';
import {CartService} from '../services/cart.service';
import {User} from '../models/user';

declare var gtag;

@Component({
  templateUrl: './shopCheckout.component.html',
  styleUrls: ['./shopCheckout.component.less']
})
export class ShopCheckoutComponent implements OnInit {
  title = 'fit-shop';
  tsGroups: any[];
  banks: Bank[] = [];
  cart: Cart;
  total: number = 0;
  isLoggedIn$: Observable<boolean>;
  finishingOrder: boolean = false;

  constructor(
    private route: ActivatedRoute,
    paymentService: PaymentService,
    private http: HttpClient,
    private accountService: AccountService,
    private cartService: CartService,
    private router: Router,
  ) {
    this.isLoggedIn$ = this.accountService.isLoggedIn;

    // paymentService.getBanks().pipe(first())
    //   .subscribe((res: Bank[]) => {
    //     this.banks = res;
    //   });

    this.cart = this.route.snapshot.data.cart;

    this.recalculateTotal();
  }

  ngOnInit(): void {
    this.registerBeginCheckoutGtag();
  }

  orderAndPayment() {
    this.finishingOrder = true;
    this.accountService.getCurrentAccount().pipe(first())
      .subscribe((user: User) => {
        this.cartService.getPaymentToken(localStorage.getItem('cartId')).pipe(first()).subscribe(
          (cart: Cart) => {
            this.cart = cart;
            this.recalculateTotal();

            window.location.href =  environment.paymentServiceUrl + cart.paymentToken;

          }, error => {

          });
      }, error => {

      });
  }

  private recalculateTotal() {
    this.total = 0;
    for (const item of this.cart.items) {
      this.total += item.quantity * item.voucherTemplate.ecommerceSalePrice;
    }
  }

  registerBeginCheckoutGtag() {
    const items = [];

    for (let i = 0; i < this.cart.items.length; i++) {
      items.push({
        item_id: this.cart.items[i].voucherTemplate.slug,
        item_name: this.cart.items[i].voucherTemplate.name,
        affiliation: 'karnety.bodyspace.pl',
        coupon: '',
        discount: 0,
        index: 0,
        item_brand: 'Body Space',
        item_category: this.cart.items[i].voucherTemplate.group.name,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: this.cart.items[i].voucherTemplate.ecommerceSalePrice,
        quantity: this.cart.items[i].quantity
      });
    }

    gtag('event', 'begin_checkout', {
      currency: 'PLN',
      value: this.total,
      items: items
    });

    gtag('event', 'add_shipping_info', {
      currency: 'PLN',
      value: this.total,
      items: items
    });

    gtag('event', 'add_payment_info', {
      currency: 'PLN',
      value: this.total,
      items: items
    });
  }
}
