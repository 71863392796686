import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountService} from '../services/account.service';
import {first} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {matchingInputsValidator} from './validators';

@Component({
  selector: 'app-register-form',
  templateUrl: './register.component.html'
})

export class RegisterComponent {
  control: UntypedFormControl = new UntypedFormControl('');
  registrationForm: UntypedFormGroup;
  errorCode: string = '';
  errorMessage: string = '';
  countries: string[] = [];
  isLoggedIn$: Observable<boolean>;
  @Output() add = new EventEmitter();
  @Input() voucher;
  registerd = false;
  registrationError = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService
  ) {
    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      company: [''],
      nip: [''],
      companyStreet: [''],
      companyCity: [''],
      companyPostCode: [''],
      companyCountry: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      street: [''],
      houseNumber: [''],
      flatNumber: [''],
      city: [''],
      postCode: [''],
      country: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      gdrp: [false, Validators.required],
      regulations: [false, Validators.required],
    }, {
      validator: matchingInputsValidator('password', 'confirmPassword')
    });
  }

  get regulationsControl() {
    return this.registrationForm.controls.regulations;
  }

  get gdrpControl() {
    return this.registrationForm.controls.gdrp;
  }

  get emailControl() {
    return this.registrationForm.controls.email;
  }

  get firstNameControl() {
    return this.registrationForm.controls.firstName;
  }

  get lastNameControl() {
    return this.registrationForm.controls.lastName;
  }

  get passwordControl() {
    return this.registrationForm.controls.password;
  }

  get confirmPasswordControl() {
    return this.registrationForm.controls.confirmPassword;
  }

  submitRegistration() {
    this.errorMessage = '';

    if (this.registrationForm.invalid) {
      return;
    }

    this.accountService.register(
      this.registrationForm.value.email,
      this.registrationForm.value.password,
      this.registrationForm.value.phone,
      this.registrationForm.value.firstName,
      this.registrationForm.value.lastName,
      this.registrationForm.value.street,
      this.registrationForm.value.houseNumber,
      this.registrationForm.value.flatNumber,
      this.registrationForm.value.city,
      this.registrationForm.value.postCode,
      this.registrationForm.value.country,
      this.registrationForm.value.company,
      this.registrationForm.value.nip,
      this.registrationForm.value.companyStreet,
      this.registrationForm.value.companyCity,
      this.registrationForm.value.companyPostCode,
      this.registrationForm.value.companyCountry
    ).pipe(first())
      .subscribe(response => {
        this.registerd = true;
        // this.isLoggedIn$ = this.accountService.isLoggedIn;
      }, error => {
        this.registrationError = true;
        console.log(error);
        // this.errorCode = error.error.code;
        // this.errorMessage = error.error.message;
      });
  }
}
