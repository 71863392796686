import {VoucherTemplate} from '../models/voucherTemplate';
import {CartService} from '../services/cart.service';
import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';
import {Cart} from '../models/cart';
import {Router} from '@angular/router';
import {AccountService} from '../services/account.service';

declare var gtag;

@Injectable({ providedIn: 'root' })
export class ShopCartService {

  constructor(
    private cartService: CartService,
    private router: Router,
    private accountService: AccountService,
  ) {

  }

  addToCart(voucher: VoucherTemplate, quantity: number) {
    const cartId = localStorage.getItem('cartId');

    this.cartService.addToCart(cartId, quantity, voucher.id).pipe(first())
      .subscribe((data: Cart) => {
        this.registerAddToCardGtag(voucher, quantity);
        localStorage.setItem('cartId', data.id);
        if (localStorage.getItem('user')) {
          this.router.navigate(['/cart']);
        } else {
          this.router.navigate(['/login']);
        }
      }, error => {

      });
  }

  removeFromCart(voucher: VoucherTemplate, quantity: number) {
    const cartId = localStorage.getItem('cartId');

    this.cartService.removeFromCart(cartId, quantity, voucher.id).pipe(first())
      .subscribe((data: Cart) => {
        this.registerRemoveFromCardGtag(voucher, quantity);
        localStorage.setItem('cartId', data.id);
      }, error => {

      });
  }

  registerAddToCardGtag(voucher: VoucherTemplate, quantity: number) {
    gtag('event', 'add_to_cart', {
      currency: 'PLN',
      value: voucher.ecommerceSalePrice,
      items: [
        {
          item_id: voucher.slug,
          item_name: voucher.name,
          affiliation: 'karnety.bodyspace.pl',
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: 'Body Space',
          item_category: voucher.group.name,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: voucher.ecommerceSalePrice,
          quantity: quantity
        }
      ]
    });
  }

  registerRemoveFromCardGtag(voucher: VoucherTemplate, quantity: number) {
    gtag('event', 'remove_from_cart', {
      currency: 'PLN',
      value: voucher.ecommerceSalePrice,
      items: [
        {
          item_id: voucher.slug,
          item_name: voucher.name,
          affiliation: 'karnety.bodyspace.pl',
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: 'Body Space',
          item_category: voucher.group.name,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: voucher.ecommerceSalePrice,
          quantity: quantity
        }
      ]
    });
  }
}
