import {Component} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {AccountService} from '../../services/account.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})

export class MenuComponent {
  isLoggedIn$: Observable<boolean>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService
  ) {
    this.isLoggedIn$ = this.accountService.isLoggedIn;
  }
}
