import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AccountService} from '../services/account.service';

@Component({
  templateUrl: './accountDataForm.component.html',
  styleUrls: ['./accountDataForm.component.less']
})
export class AccountDataFormComponent implements OnInit, OnDestroy {
  title = 'account-data-form-page';
  userData: User;
  control: UntypedFormControl = new UntypedFormControl('');
  editForm: UntypedFormGroup;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.userData = this.route.snapshot.data.userData;

    this.editForm = this.formBuilder.group({
      firstName: [this.userData.firstName, Validators.required],
      lastName: [this.userData.lastName, Validators.required],
      company: [this.userData.companyName],
      nip: [this.userData.nip],
      companyStreet: [this.userData.companyStreet],
      companyCity: [this.userData.companyCity],
      companyPostCode: [this.userData.companyPostCode],
      companyCountry: [this.userData.companyCountry],
      phone: [this.userData.phone],
      street: [this.userData.street],
      houseNumber: [this.userData.houseNumber],
      flatNumber: [this.userData.flatNumber],
      city: [this.userData.city],
      postCode: [this.userData.postCode],
      country: [this.userData.country],
    });
  }

  ngOnDestroy() {

  }

  get f() { return this.editForm.controls; }

  get regulationsControl() {
    return this.editForm.controls.regulations;
  }

  get firstNameControl() {
    return this.editForm.controls.firstName;
  }

  get lastNameControl() {
    return this.editForm.controls.lastName;
  }

  get passwordControl() {
    return this.editForm.controls.password;
  }

  get confirmPasswordControl() {
    return this.editForm.controls.confirmPassword;
  }

  submit() {
    this.errorMessage = '';

    if (this.editForm.invalid) {
      return;
    }

    this.accountService.update(
      this.editForm.value.phone,
      this.editForm.value.firstName,
      this.editForm.value.lastName,
      this.editForm.value.street,
      this.editForm.value.houseNumber,
      this.editForm.value.flatNumber,
      this.editForm.value.city,
      this.editForm.value.postCode,
      this.editForm.value.country,
      this.editForm.value.company,
      this.editForm.value.nip,
      this.editForm.value.companyStreet,
      this.editForm.value.companyCity,
      this.editForm.value.companyPostCode,
      this.editForm.value.companyCountry
    ).pipe(first())
      .subscribe(response => {
        this.router.navigate(['/account/data']);
        // this.isLoggedIn$ = this.accountService.isLoggedIn;
      }, error => {
        console.log(error);
        // this.errorCode = error.error.code;
        // this.errorMessage = error.error.message;
      });
  }
}
