import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ShopCartComponent} from './shopCart.component';
import {CartResolve} from './resolver/cart.resolve';
import {RouterModule} from '@angular/router';
import {MinusFromCartComponent} from './minusFromCart.component';
import {AddIntoCartComponent} from './addIntoCart.component';
// import {PdfViewerModule} from 'ng2-pdf-viewer';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        ShopCartComponent,
        MinusFromCartComponent,
        AddIntoCartComponent,
    ],
  imports: [
    BrowserModule,
    RouterModule,
    // PdfViewerModule,
    FormsModule,
  ],
  providers: [
    CartResolve,
  ],
  exports: [
  ],
  bootstrap: [
    ShopCartComponent,
  ]
})
export class ShopCartModule { }
