import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AccountService} from '../services/account.service';
import {first} from 'rxjs/operators';
import {AlertService} from '../shared/alert/alert.service';
import {ResetPasswordService} from '../services/resetPassword.service';

@Component({
  templateUrl: './requestResetPassword.component.html',
  styleUrls: ['./requestResetPassword.component.less']
})
export class RequestResetPasswordComponent implements OnInit, OnDestroy {
  title = 'request-reset-password-page';
  changePasswordForm: UntypedFormGroup;
  errorMessage: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private alertService: AlertService,
    private resetPasswordService: ResetPasswordService,
  ) {
    this.changePasswordForm = this.formBuilder.group({
      username: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  get usernameControl() {
    return this.changePasswordForm.controls.username;
  }

  submit() {
    this.errorMessage = '';

    if (this.changePasswordForm.invalid) {
      return;
    }

    this.resetPasswordService.requestResetPassword(
      this.changePasswordForm.value.username).pipe(first())
      .subscribe(response => {
        this.alertService.success('Na podany adres e-mail zostały przesłane dalsze instrukcje.', { keepAfterRouteChange: true });
        this.router.navigate(['/request-reset-password-confirmation']);
      }, error => {
        this.errorMessage = 'Nie można zmienić hasła.';
      });
  }
}
