import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ShopProductComponent} from './shopProduct.component';
import {VoucherResolve} from './resolver/voucher.resolve';
import {AddToCartComponent} from '../shopCart/addToCart.component';

@NgModule({
    declarations: [
        ShopProductComponent,
        AddToCartComponent,
    ],
  imports: [
    BrowserModule,
  ],
  providers: [
    VoucherResolve,
  ],
  exports: [
    AddToCartComponent
  ],
  bootstrap: [
    ShopProductComponent,
  ]
})
export class ShopProductModule { }
