import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {PaymentConfirmationComponent} from './paymentConfirmation.component';

@NgModule({
  declarations: [
    PaymentConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
  ],
  providers: [],
  exports: [
  ],
  bootstrap: [
    PaymentConfirmationComponent,
  ]
})
export class PaymentConfirmationModule { }
