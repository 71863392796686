import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../../environments/environment';
import {Cart} from '../models/cart';
import {map} from 'rxjs/operators';
import {AccountService} from './account.service';
import {Order} from '../models/order';

@Injectable({ providedIn: 'root' })
export class CartService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private accountService: AccountService,
  ) {

  }

  getCart(id: string) {
    return this.http.get<Cart>(`${environment.apiEndpoint}/v1/cart/get/` + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }

  calculateTransactionMd5(id: string) {
    return this.http.get<Cart>(`${environment.apiEndpoint}/v1/cart/calculate/` + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
      }),
    });
  }

  getPaymentToken(id: string) {
    return this.http.get<Cart>(`${environment.internalApiEndpoint}/v1/cart/payment-token/` + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
        Authorization: 'Bearer ' + this.accountService.getToken(),
      }),
    });
  }

  getOrderDetails(id: string) {
    return this.http.get<Order>(`${environment.internalApiEndpoint}/v1/orders/by-cart/` + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey,
        Authorization: 'Bearer ' + this.accountService.getToken(),
      }),
    });
  }

  addToCart(cartId: string|null, quantity: number, voucherId: string) {
    return this.http.post<Cart>(`${environment.apiEndpoint}/v1/cart/add`, {
      cartId: cartId,
      quantity: quantity,
      voucherId: voucherId
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey
      }),
    }).pipe(map((x: Cart) => {
      return x;
    }));
  }

  removeFromCart(cartId: string|null, quantity: number, voucherId: string) {
    return this.http.post<Cart>(`${environment.apiEndpoint}/v1/cart/remove/` + cartId + `/` + voucherId + `/` + quantity, {}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey
      }),
    });
  }

  setCartItem(cartId: string|null, quantity: number, voucherId: string) {
    return this.http.post<Cart>(`${environment.apiEndpoint}/v1/cart/set/` + cartId + `/` + voucherId + `/` + quantity, {}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey
      }),
    });
  }
}
