import {Component, OnDestroy, OnInit} from '@angular/core';
import 'rxjs/add/operator/takeWhile';

@Component({
  templateUrl: './registerPage.component.html',
  styleUrls: ['./registerPage.component.less']
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  title = 'register-page';

  constructor(
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }
}
