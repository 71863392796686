import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {CartService} from '../../services/cart.service';
import {Cart} from '../../models/cart';

@Injectable()
export class CartResolve  {
  constructor(private cartService: CartService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Cart|null> {
    if (localStorage.getItem('cartId')) {
      return this.cartService.getCart(localStorage.getItem('cartId'));
    } else {
      return null;
    }
  }
}
