import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {VoucherTemplate} from '../models/voucherTemplate';
import {ActivatedRoute} from '@angular/router';
import {CartService} from '../services/cart.service';
import {ShopCartService} from '../shopCart/shopCart.service';
import {first} from 'rxjs/operators';
import {Gallery} from '../models/gallery';
import {PublicApiService} from '../services/publicApi.service';

declare var gtag;

@Component({
  templateUrl: './shopProduct.component.html',
  styleUrls: ['./shopProduct.component.less']
})
export class ShopProductComponent implements OnInit {
  title = 'fit-shop';
  voucher: VoucherTemplate;
  loading = false;

  @Output() voucherAdded = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private shopCartService: ShopCartService,
    private publicApiService: PublicApiService,
  ) {
  }

  ngOnInit(): void {
    this.voucher = this.route.snapshot.data.voucher;
    this.loading = true;

    this.publicApiService.getVoucherGallery(this.voucher.id).pipe(first())
      .subscribe((gallery: Gallery) => {
        this.voucher.gallery = gallery;
        for (let j = 0; j < this.voucher.gallery.items.length; j++) {
          if (!this.voucher.gallery.items[j].fillImage) {
            continue;
          }

          this.publicApiService.getImage(this.voucher.gallery.items[j].id, this.voucher.gallery.id).pipe(first())
            .subscribe((image: string) => {
              this.voucher.gallery.items[j].image = image;
              this.voucher.gallery.fillImage = image;
              this.voucher.gallery.fillImageAlt = this.voucher.gallery.items[j].altName;
              this.loading = false;
            });
        }
      });

    this.registerGtag(this.voucher);
  }

  addVoucherToCart(voucher: VoucherTemplate, quantity: number) {
    this.shopCartService.addToCart(voucher, quantity);
  }

  registerGtag(voucher: VoucherTemplate) {
    gtag('event', 'view_item', {
        currency: 'PLN',
        value: voucher.ecommerceSalePrice,
      items: [
        {
          item_id: voucher.slug,
          item_name: voucher.name,
          affiliation: 'karnety.bodyspace.pl',
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: 'Body Space',
          item_category: voucher.group.name,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: voucher.ecommerceSalePrice,
          quantity: 1
        }
      ]
    });
  }

}
