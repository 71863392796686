import {Component} from '@angular/core';
import {AccountService} from '../services/account.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-account-menu',
  templateUrl: './accountMenu.component.html',
  styleUrls: ['./accountMenu.component.less']
})

export class AccountMenuComponent {
  isLoggedIn$: Observable<boolean>;

  constructor(
    private accountService: AccountService
  ) {
    this.isLoggedIn$ = this.accountService.isLoggedIn;
  }
}
