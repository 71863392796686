import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user';
import {first, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {RefreshToken} from '../models/refreshToken';
import {Cart} from '../models/cart';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private confirmed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();

    if (this.userSubject.value) {
      this.loggedIn.next(true);
    }
  }

  public get currentUser(): User {
    return this.userSubject.value;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isConfirmed() {
    return this.confirmed.asObservable();
  }

  login(username, password) {
    return this.http.post<User>(`${environment.loginApiEndpoint}/auth/login`, { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', user.token);
        localStorage.setItem('refreshToken', user.refreshToken);
        localStorage.setItem('refreshTokenExpiration', user.refreshTokenExpiration.toString());

        this.userSubject.next(user);
        this.loggedIn.next(true);

        return this.getCurrentAccount().pipe(first())
          .subscribe(x => {
            if (x.confirmedAt) {
              this.confirmed.next(true);
            } else {
              this.confirmed.next(false);
            }

            user.username = x.username;
            user.firstName = x.firstName;
            user.lastName = x.lastName;
            user.street = x.street;
            user.houseNumber = x.houseNumber;
            user.flatNumber = x.flatNumber;
            user.city = x.city;
            user.postCode = x.postCode;
            user.country = x.country;
            user.email = x.email;
            user.phone = x.phone;
            user.roles = x.roles;
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            this.loggedIn.next(true);
            return user;
          });
      }));
  }

  register(
    email: string, password: string, phone: string,
    firstName: string, lastName: string,
    street: string, houseNumber: string, flatNumber: string,
    city: string, postCode: string, country: string,
    companyName: string, vatId: string, companyStreet: string,
    companyCity: string, companyPostCode: string,
    companyCountry: string
  ) {
    return this.http.post<User>(`${environment.apiEndpoint}/v1/users/register`, {
      email: email,
      plainPassword: password,
      phone: phone,
      firstName: firstName,
      lastName: lastName,
      street: street,
      houseNumber: houseNumber,
      flatNumber: flatNumber,
      city: city,
      postCode: postCode,
      country: country,
      companyName: companyName,
      vatId: vatId,
      nip: vatId,
      companyStreet: companyName,
      companyCity: companyCity,
      companyPostCode: companyPostCode,
      companyCountry: companyCountry
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.apikey
      })
    }).pipe(map(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // return this.login(email, password).pipe(first())
      //   .subscribe();
    }));
  }

  getCurrentAccount() {
    return this.http.get<User>(`${environment.loginApiEndpoint}/v1/user/get-current`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
      }),
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    localStorage.removeItem('cartId');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiration');
    this.userSubject.next(null);
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      this.logout();
    }

    this.http.post<RefreshToken>(`${environment.loginApiEndpoint}/token/refresh`, {
        refreshToken: refreshToken,
      }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
      })
    }).pipe(first())
      .subscribe((newRefreshToken: RefreshToken) => {
        localStorage.setItem('token', newRefreshToken.token);
        localStorage.setItem('refreshToken', newRefreshToken.refreshToken);
        localStorage.setItem('refreshTokenExpiration', newRefreshToken.refreshTokenExpiration.toString());
      }, error => {
        this.logout();
      });
  }

  changePassword(currentPassword: string, password: string, confirmPassword: string) {
    return this.http.put<User>(`${environment.loginApiEndpoint}/v1/user/account/change-password`, {
      'currentPassword': currentPassword,
      'password': {
        'first': password,
        'second': confirmPassword
      }
    },{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
      }),
    });
  }

  confirmAccount(token: string) {
    return this.http.get(`${environment.apiHost}confirm/` + token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }

  createFirstTimePassword(email: string) {
    return this.http.post(`${environment.apiHost}first-time-password`, {
      'email': email
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }

  update(
    phone: string,
    firstName: string, lastName: string,
    street: string, houseNumber: string, flatNumber: string,
    city: string, postCode: string, country: string,
    companyName: string, vatId: string, companyStreet: string,
    companyCity: string, companyPostCode: string,
    companyCountry: string
  ) {
    return this.http.put<User>(`${environment.internalApiEndpoint}/v1/user/current-account/update`, {
      phone: phone,
      firstName: firstName,
      lastName: lastName,
      street: street,
      houseNumber: houseNumber,
      flatNumber: flatNumber,
      city: city,
      postCode: postCode,
      country: country,
      companyName: companyName,
      vatId: vatId,
      nip: vatId,
      companyStreet: companyName,
      companyCity: companyCity,
      companyPostCode: companyPostCode,
      companyCountry: companyCountry
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
      })
    }).pipe(map(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      return this.getCurrentAccount().pipe(first())
        .subscribe();
    }));
  }
}
