import {Component, OnDestroy, OnInit} from '@angular/core';
import 'rxjs/add/operator/takeWhile';
import {AccountService} from '../services/account.service';

@Component({
  templateUrl: './logoutPage.component.html',
  styleUrls: ['./logoutPage.component.less']
})
export class LogoutPageComponent implements OnInit, OnDestroy {
  title = 'logout-page';

  constructor(
    private accountService: AccountService,
  ) {
  }

  ngOnInit(): void {
    this.accountService.logout();
  }

  ngOnDestroy() {

  }
}
