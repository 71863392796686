import {Component, OnDestroy, OnInit} from '@angular/core';
import {PublicApiService} from '../services/publicApi.service';
import {VoucherTemplate} from '../models/voucherTemplate';
import {first, observeOn} from 'rxjs/operators';
import {VoucherTemplates} from '../models/voucherTemplates';
import {ShopCartService} from '../shopCart/shopCart.service';
import {asyncScheduler, Observable, of} from 'rxjs';
import {Cart} from '../models/cart';
import {CartService} from '../services/cart.service';
import {IntervalObservable} from 'rxjs-compat/observable/IntervalObservable';
import 'rxjs/add/operator/takeWhile';
import {Order} from '../models/order';

declare var gtag;

@Component({
  templateUrl: './paymentConfirmation.component.html',
  styleUrls: ['./paymentConfirmation.component.less']
})
export class PaymentConfirmationComponent implements OnInit, OnDestroy {
  title = 'fit-shop';
  vouchers: VoucherTemplate[];
  alive = true;
  error = false;
  cart: Cart;
  order: Order;
  total: number = 0;

  constructor(
    private publicApiService: PublicApiService,
    private shopCartService: ShopCartService,
    private cartService: CartService,
  ) {
    this.cart = new Cart();
    this.cart.paid = false;

    IntervalObservable.create(5000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        cartService.getCart(localStorage.getItem('cartId')).pipe(first()).subscribe(
          (cart: Cart) => {
            if (cart.paid) {
              this.alive = false;

              cartService.getOrderDetails(localStorage.getItem('cartId')).pipe(first()).subscribe(
                (order: Order) => {
                  this.order = order;
                }, error => {

                }
              );

              this.cart = cart;
              this.recalculateTotal();

              this.registerPurchaseGtag();
            }

            this.cart = cart;
          }, error => {

          }
        );
      });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.alive = false; // switches your IntervalObservable off
  }

  private recalculateTotal() {
    this.total = 0;
    for (const item of this.cart.items) {
      this.total += item.quantity * item.voucherTemplate.ecommerceSalePrice;
    }
  }

  registerPurchaseGtag() {
    const items = [];

    for (let i = 0; i < this.cart.items.length; i++) {
      items.push({
        item_id: this.cart.items[i].voucherTemplate.slug,
        item_name: this.cart.items[i].voucherTemplate.name,
        affiliation: 'karnety.bodyspace.pl',
        coupon: '',
        discount: 0,
        index: 0,
        item_brand: 'Body Space',
        item_category: this.cart.items[i].voucherTemplate.group.name,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: this.cart.items[i].voucherTemplate.ecommerceSalePrice,
        quantity: this.cart.items[i].quantity
      });
    }

    gtag('event', 'purchase', {
      currency: 'PLN',
      value: this.total,
      items: items
    });
  }
}
