import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {CardListComponent} from './cardList.component';
import {AccountModule} from '../account/account.module';
import {UserCardsResolve} from './resolver/userCards.resolve';

@NgModule({
  declarations: [
    CardListComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AccountModule,
  ],
  providers: [
    UserCardsResolve,
  ],
  exports: [
  ],
  bootstrap: [
    CardListComponent,
  ]
})
export class CardModule { }
