import {Component, Input, OnInit} from '@angular/core';
import {VoucherTemplate} from '../models/voucherTemplate';
import {CartService} from '../services/cart.service';
import {Cart} from '../models/cart';
import {ActivatedRoute, Router} from '@angular/router';
import {ShopCartService} from './shopCart.service';
import {first} from 'rxjs/operators';
import {AccountService} from '../services/account.service';

declare var gtag;

@Component({
  templateUrl: './shopCart.component.html',
  styleUrls: ['./shopCart.component.less']
})
export class ShopCartComponent implements OnInit {
  title = 'fit-shop';
  @Input() vouchers: VoucherTemplate[];
  cart: Cart;
  total: number = 0;
  regulations = false;
  gdrp = false;
  allRegulationsChecked = true;

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    private shopCartService: ShopCartService,
    private router: Router,
    private accountService: AccountService,
  ) {
    this.cart = this.route.snapshot.data.cart;

    this.recalculateTotal();
  }

  ngOnInit(): void {
    // if (!this.accountService.currentUser.confirmedAt) {
    //   this.router.navigate(['/accept-regulations-and-change-password']);
    // }

    this.registerShowCardGtag();
  }

  minusVoucherFromCart(voucher: VoucherTemplate, quantity: number) {
    this.cartService.removeFromCart(localStorage.getItem('cartId'), quantity, voucher.id).pipe(first())
      .subscribe((cart: Cart) => {
        this.cart = cart;
        this.recalculateTotal();
        this.registerRemoveFromCardGtag(voucher, quantity);
      }, error => {

      });
  }

  addVoucherIntoCart(voucher: VoucherTemplate, quantity: number) {
    this.cartService.addToCart(localStorage.getItem('cartId'), quantity, voucher.id).pipe(first())
      .subscribe((cart: Cart) => {
        this.cart = cart;
        this.recalculateTotal();
        this.registerAddToCardGtag(voucher, quantity);
      }, error => {

      });
  }

  setVoucherQuantity(voucher: VoucherTemplate, event) {
    this.cartService.setCartItem(localStorage.getItem('cartId'), event.target.value, voucher.id).pipe(first()).subscribe(
      (cart: Cart) => {
        this.cart = cart;
        this.recalculateTotal();
      }, error => {

      }
    );
  }

  setVoucherQuantityByQty(voucher: VoucherTemplate, quantity: number) {
    this.cartService.setCartItem(localStorage.getItem('cartId'), quantity, voucher.id).pipe(first()).subscribe(
      (cart: Cart) => {
        this.cart = cart;
        this.recalculateTotal();
      }, error => {

      }
    );
  }

  isChecked($event, field: string) {
    if (field === 'gdrp') {
      this.gdrp = $event.target.checked;
    } else {
      this.regulations = $event.target.checked;
    }

    if (this.gdrp && this.regulations) {
      this.allRegulationsChecked = true;
    }
  }

  goToCheckout() {
    if (this.gdrp && this.regulations) {
      this.allRegulationsChecked = true;
      this.router.navigate(['/checkout']);
    } else {
      this.allRegulationsChecked = false;
    }
  }

  private recalculateTotal() {
    this.total = 0;
    if (this.cart) {
      for (const item of this.cart.items) {
        this.total += item.quantity * item.voucherTemplate.ecommerceSalePrice;
      }
    }
  }

  registerAddToCardGtag(voucher: VoucherTemplate, quantity: number) {
    gtag('event', 'add_to_cart', {
      currency: 'PLN',
      value: voucher.ecommerceSalePrice,
      items: [
        {
          item_id: voucher.slug,
          item_name: voucher.name,
          affiliation: 'karnety.bodyspace.pl',
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: 'Body Space',
          item_category: voucher.group.name,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: voucher.ecommerceSalePrice,
          quantity: quantity
        }
      ]
    });
  }

  registerRemoveFromCardGtag(voucher: VoucherTemplate, quantity: number) {
    gtag('event', 'remove_from_cart', {
      currency: 'PLN',
      value: voucher.ecommerceSalePrice,
      items: [
        {
          item_id: voucher.slug,
          item_name: voucher.name,
          affiliation: 'karnety.bodyspace.pl',
          coupon: '',
          discount: 0,
          index: 0,
          item_brand: 'Body Space',
          item_category: voucher.group.name,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: voucher.ecommerceSalePrice,
          quantity: quantity
        }
      ]
    });
  }

  registerShowCardGtag() {
    const items = [];

    for (let i = 0; i < this.cart.items.length; i++) {
      items.push({
        item_id: this.cart.items[i].voucherTemplate.slug,
        item_name: this.cart.items[i].voucherTemplate.name,
        affiliation: 'karnety.bodyspace.pl',
        coupon: '',
        discount: 0,
        index: 0,
        item_brand: 'Body Space',
        item_category: this.cart.items[i].voucherTemplate.group.name,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: this.cart.items[i].voucherTemplate.ecommerceSalePrice,
        quantity: this.cart.items[i].quantity
      });
    }

    gtag('event', 'view_cart', {
      currency: 'PLN',
      value: this.total,
      items: items
    });
  }
}
