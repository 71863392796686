import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../models/user';

@Component({
  templateUrl: './accountData.component.html',
  styleUrls: ['./accountData.component.less']
})
export class AccountDataComponent implements OnInit, OnDestroy {
  title = 'account-data-page';
  userData: User;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.userData = this.route.snapshot.data.userData;
  }

  ngOnDestroy() {

  }
}
