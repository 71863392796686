export const environment = {
  production: true,
  merchantId: '10100',
  apikey: 'abc',
  apiEndpoint: 'https://api.bodyspace.eu/public-api',
  loginApiEndpoint: 'https://api.bodyspace.eu/api',
  paymentServiceUrl: 'https://secure.przelewy24.pl/trnRequest/',
  internalApiEndpoint: 'https://api.bodyspace.eu/api',
  apiHost: 'https://api.bodyspace.eu/',
};


// export const environment = {
//   production: true,
//   merchantId: '10100',
//   apikey: 'abc',
//   apiEndpoint: 'https://api.bodyspace.eu/public-api',
//   loginApiEndpoint: 'https://api.bodyspace.eu/api',
//   paymentServiceUrl: 'https://secure.przelewy24.pl/trnRequest/',
//   internalApiEndpoint: 'https://api.bodyspace.eu/api',
//   apiHost: 'https://api.bodyspace.eu/',
// };
