import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RegisterComponent} from './register.component';
import {LoginPageComponent} from './loginPage.component';
import {RegisterPageComponent} from './registerPage.component';
import {LogoutPageComponent} from './logoutPage.component';
import {AccountPageComponent} from './accountPage.component';
import {AccountMenuComponent} from './accountMenu.component';
import {AlertModule} from '../shared/alert/alert.module';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    LoginPageComponent,
    RegisterPageComponent,
    LogoutPageComponent,
    AccountPageComponent,
    AccountMenuComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    AlertModule,
  ],
  providers: [
  ],
  exports: [
    LoginComponent,
    RegisterComponent,
    AccountMenuComponent,
  ],
  bootstrap: [
    LoginPageComponent,
    RegisterPageComponent,
    LogoutPageComponent,
    AccountPageComponent,
  ]
})
export class AccountModule { }
