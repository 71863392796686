import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Orders} from '../../models/orders';
import {OrderService} from '../../services/order.service';

@Injectable()
export class OrdersResolve  {
  constructor(private orderService: OrderService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Orders> {
    return this.orderService.findUserOrders();
  }
}
