import {Component, OnDestroy, OnInit} from '@angular/core';
import {CardService} from '../services/card.service';
import {Card} from '../models/card';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './cardList.component.html',
  styleUrls: ['./cardList.component.less']
})
export class CardListComponent implements OnInit, OnDestroy {
  title = 'card-list-page';
  cards: Card[];

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.cards = this.route.snapshot.data.cards;
  }

  ngOnDestroy() {

  }
}
