import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {AccountModule} from '../account/account.module';
import {OrdersResolve} from './resolver/orders.resolve';
import {OrdersListComponent} from './ordersList.component';

@NgModule({
  declarations: [
    OrdersListComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AccountModule,
  ],
  providers: [
    OrdersResolve,
  ],
  exports: [
  ],
  bootstrap: [
    OrdersListComponent,
  ]
})
export class OrderModule { }
