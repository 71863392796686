import {Component, OnDestroy, OnInit} from '@angular/core';
import 'rxjs/add/operator/takeWhile';
import {AccountService} from '../services/account.service';
import {Router} from '@angular/router';

@Component({
  templateUrl: './accountPage.component.html',
  styleUrls: ['./accountPage.component.less']
})
export class AccountPageComponent implements OnInit, OnDestroy {
  title = 'account-page';

  constructor(
    private router: Router,
    private accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    // if (!this.accountService.currentUser.confirmedAt) {
    //   this.router.navigate(['/accept-regulations-and-change-password']);
    // }
  }

  ngOnDestroy() {

  }
}
