import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AccountService} from '../services/account.service';
import {first} from 'rxjs/operators';

@Component({
  templateUrl: './accountConfirmation.component.html',
  styleUrls: ['./accountConfirmation.component.less']
})
export class AccountConfirmationComponent implements OnInit, OnDestroy {
  title = 'account-confirmation-page';
  token: string;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
  ) {
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.params.token;
    this.accountService.confirmAccount(this.token).pipe(first())
      .subscribe(x => {});
  }

  ngOnDestroy() {

  }
}
